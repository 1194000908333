import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store/index.js'
// import VueGtag from "vue-gtag";
Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior () {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve({ x: 0, y: 0 })
      }, 100)
    })
  },
  routes: [
    {
      path: '/', 
      name: 'Home',
      meta: {
        homeSlide: -1
      },
      component: () => import(/* webpackChunkName: "Home" */ '@/views/Home.vue')
    },
    {
      path: '/faces', 
      name: 'Faces',
      meta: {
        homeSlide: 3
      },
      component: () => import(/* webpackChunkName: "Faces" */ '@/views/Faces.vue')
    },
    {
      path: '/quiz', 
      name: 'PersonalityQuiz',
      meta: {
        homeSlide: 2
      },
      component: () => import(/* webpackChunkName: "PersonalityQuiz" */ '@/views/PersonalityQuiz.vue')
    },
    {
      path: '/quiz/result', 
      name: 'PersonalityQuizResult',
      component: () => import(/* webpackChunkName: "PersonalityQuizResult" */ '@/views/PersonalityQuizResult.vue')
    },
    {
      path: '/media', 
      name: 'MediaLibrary',
      meta: {
        homeSlide: 1
      },
      component: () => import(/* webpackChunkName: "MediaLibrary" */ '@/views/MediaLibrary.vue')
    },
    {
      path: '/media/:id',
      props: true,
      name: 'MediaDisplay',
      component: () => import(/* webpackChunkName: "MediaDisplay" */ '@/views/MediaDisplay.vue')
    },
    { 
      path: '*', 
      redirect: '/' 
    }
  ]
})

router.beforeEach((to, from, next) => {
  store.state.previousPage = from;
  next();
  // if (to.meta.isDraw && !store.state.applicationStarted) {
  //   next({
  //     path: '/'
  //   })
  //   return false
  // } else {
  //   next()
  // }
})



// Vue.use(VueGtag, {
//   config: { id: 'UA-995853-8' }
// }, router);

export default router