import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    bodyBackground: "bg-none",
    showProgress: false,
    hubSlideNumber: -1,
    home: false,
    previousPage: null,
    homeIntroPresented: false,
    cameraMoveTo: 'intro',
    logoHide: false,
    navHide: true,
    moreHide: true,
    browser: null,
    isMobile: false,
    quizResult: null
  },
  mutations: {
  },
  actions: {
  },
})
