import Vue from 'vue'
// import { firestorePlugin } from 'vuefire'
import App from './App.vue'
import router from './router'
import store from './store'


// Import Bootstrap Vue
import BootstrapVue from 'bootstrap-vue'
Vue.use(BootstrapVue)
// Vue.use(firestorePlugin)

Vue.config.productionTip = false

// // Scrollto
// import VueScrollTo from 'vue-scrollto'
// Vue.use(VueScrollTo)


// Social sharing
import VueSocialSharing from 'vue-social-sharing'
Vue.use(VueSocialSharing);

// Carousel
import VueFlicking from "@egjs/vue-flicking";
Vue.use(VueFlicking);


// ==== Custom componenents
import Topbar from './components/Topbar.vue'
Vue.component('Topbar', Topbar)

import FooterGlobal from './components/FooterGlobal.vue'
Vue.component('FooterGlobal', FooterGlobal)

import SceneThree from './components/SceneThree.vue'
Vue.component('SceneThree', SceneThree)


// Detect Browser
var { detect } = require('detect-browser')
var browser = detect()
if (browser) {
  console.log(browser.name, browser.version, browser.os)
  var browserString = browser.os.toLowerCase();
  if (browserString.includes("android") || browserString.includes("ios")) {
    store.state.isMobile = true;
  }
}


// Global methods
Vue.mixin({
  methods: {
    blurButtons () {
      console.log('blur buttons')
      setTimeout(function() {
        document.activeElement.blur()
      }, 150);
    }
  }
});


new Vue({
  router,
  store,
  // beforeCreate () {
  //   auth.init(this)
  // },
  render: h => h(App)
}).$mount('#app')
