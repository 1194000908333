<template>
  <div>
    <b-modal id="modal-share" hide-footer hide-header centered>
      <div class="text-center">
        <h1 class="mb-4">Share</h1>

        <ShareNetwork class="btn btn-primary mb-3"
            network="facebook"
            :url="shareOptions.url"
            :quote="shareOptions.text"
            :title="''"
          >
            <span>Facebook</span>
            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24"><path d="M9 8h-3v4h3v12h5v-12h3.642l.358-4h-4v-1.667c0-.955.192-1.333 1.115-1.333h2.885v-5h-3.808c-3.596 0-5.192 1.583-5.192 4.615v3.385z"/></svg>
        </ShareNetwork>
        <br>

        <ShareNetwork class="btn btn-primary mb-3"
            network="twitter"
            :url="''"
            :title="shareOptions.text"
          >
            <span>Twitter</span>
            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24"><path d="M24 4.557c-.883.392-1.832.656-2.828.775 1.017-.609 1.798-1.574 2.165-2.724-.951.564-2.005.974-3.127 1.195-.897-.957-2.178-1.555-3.594-1.555-3.179 0-5.515 2.966-4.797 6.045-4.091-.205-7.719-2.165-10.148-5.144-1.29 2.213-.669 5.108 1.523 6.574-.806-.026-1.566-.247-2.229-.616-.054 2.281 1.581 4.415 3.949 4.89-.693.188-1.452.232-2.224.084.626 1.956 2.444 3.379 4.6 3.419-2.07 1.623-4.678 2.348-7.29 2.04 2.179 1.397 4.768 2.212 7.548 2.212 9.142 0 14.307-7.721 13.995-14.646.962-.695 1.797-1.562 2.457-2.549z"/></svg>
        </ShareNetwork>
        <br>

        <ShareNetwork class="btn btn-primary mb-3"
            network="linkedin"
            :url="'https://www.hult.edu/locations'"
            :title="''"
          >
            <span>LinkedIn</span>
            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24"><path d="M4.98 3.5c0 1.381-1.11 2.5-2.48 2.5s-2.48-1.119-2.48-2.5c0-1.38 1.11-2.5 2.48-2.5s2.48 1.12 2.48 2.5zm.02 4.5h-5v16h5v-16zm7.982 0h-4.968v16h4.969v-8.399c0-4.67 6.029-5.052 6.029 0v8.399h4.988v-10.131c0-7.88-8.922-7.593-11.018-3.714v-2.155z"/></svg>
        </ShareNetwork>
        <br>

        <ShareNetwork class="btn btn-primary mb-3"
            network="whatsapp"
            :url="''"
            :title="''"
            :description="shareOptions.text"
          >
            <span>WhatsApp</span>
            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24"><path d="M.057 24l1.687-6.163c-1.041-1.804-1.588-3.849-1.587-5.946.003-6.556 5.338-11.891 11.893-11.891 3.181.001 6.167 1.24 8.413 3.488 2.245 2.248 3.481 5.236 3.48 8.414-.003 6.557-5.338 11.892-11.893 11.892-1.99-.001-3.951-.5-5.688-1.448l-6.305 1.654zm6.597-3.807c1.676.995 3.276 1.591 5.392 1.592 5.448 0 9.886-4.434 9.889-9.885.002-5.462-4.415-9.89-9.881-9.892-5.452 0-9.887 4.434-9.889 9.884-.001 2.225.651 3.891 1.746 5.634l-.999 3.648 3.742-.981zm11.387-5.464c-.074-.124-.272-.198-.57-.347-.297-.149-1.758-.868-2.031-.967-.272-.099-.47-.149-.669.149-.198.297-.768.967-.941 1.165-.173.198-.347.223-.644.074-.297-.149-1.255-.462-2.39-1.475-.883-.788-1.48-1.761-1.653-2.059-.173-.297-.018-.458.13-.606.134-.133.297-.347.446-.521.151-.172.2-.296.3-.495.099-.198.05-.372-.025-.521-.075-.148-.669-1.611-.916-2.206-.242-.579-.487-.501-.669-.51l-.57-.01c-.198 0-.52.074-.792.372s-1.04 1.016-1.04 2.479 1.065 2.876 1.213 3.074c.149.198 2.095 3.2 5.076 4.487.709.306 1.263.489 1.694.626.712.226 1.36.194 1.872.118.571-.085 1.758-.719 2.006-1.413.248-.695.248-1.29.173-1.414z"/></svg>
        </ShareNetwork>
        <br>

        <ShareNetwork class="btn btn-primary mb-3"
            network="email"
            :url="shareOptions.url"
            :title="shareOptions.text"
            :description="shareOptions.text"
          >
            <span>Email</span>
        </ShareNetwork>
        <br>

        <div class="d-block mt-3">
          <div class="d-flex justify-content-center">
            <button class="btn btn-link" v-on:click="$bvModal.hide('modal-share')">Close</button>
          </div>
        </div>
      </div>
    </b-modal>

    <div class="footer">
      <!-- <b-link class="btn btn-link footer-home" to="/" v-bind:class="{'footer-home-hidden': $store.state.home }">Home</b-link> -->
      <div class="footer-logo" v-bind:class="{'footer-logo-hidden': !$store.state.moreHide }">Powered by <a class="link" target="_blank" href="https://www.hult.edu/">Hult</a></div>
    </div>
  </div>
</template>

<script>
import { EventBus } from "@/event-bus.js"; 

export default {
  name: 'FooterGlobal',
  data() {
    return {
      isWebShare: false,
      shareOptions: {
        url: 'https://www.hult.edu/',
        title: '',
        text: ''
      }
    }
  },
  computed: {},
  mounted() {
    var self = this
    EventBus.$on("share", function (obj) {
      self.shareOptions = obj;
      self.$bvModal.show('modal-share');
    })
  },
  methods: {
    // shareOnMobile () {
    //   var self = this
    //   navigator.share({
    //     title: self.shareOptions.title,
    //     text: self.shareOptions.text,
    //     url: self.shareOptions.url
    //   })
    // }
  }
}
</script>
