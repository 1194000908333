<template>
  <div id="particles" class="particles"></div>
</template>

<script>
import * as Three from 'three';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js';
import { gsap } from "gsap";
// import Stats from "stats.js"

export default {
  name: 'SceneThree',
  data() {
    return {
      camera: null,
      scene: null,
      group: null,
      groups: [],
      renderer: null,
      mesh: null,
      cameraPos: {x: 0, y: 30, z: 0},
      sceneFreeSpin: true,
      sceneRotation: {x: 0, y: 0, z: 0},
      sceneRotationY: 0,
      // stats: null
    }
  },
  computed: {
    activeSlider () {
      return this.$store.state.hubSlideNumber;
    },
    cameraMoveToComp () {
      return this.$store.state.cameraMoveTo;
    }
  },
  methods: {
    init: function() {
      var self = this;
      var container = document.getElementById('particles');

      // Stats
      // this.stats = new Stats();
      // this.stats.showPanel( 0 ); // 0: fps, 1: ms, 2: mb, 3+: custom
      // document.body.appendChild( this.stats.dom );

      // Camera
      this.camera = new Three.PerspectiveCamera(55, window.innerWidth / window.innerHeight, 1, 100);
      this.camera.position.x = this.cameraPos.x;
      this.camera.position.z = this.cameraPos.z;
      this.camera.position.y = this.cameraPos.y;

      this.scene = new Three.Scene();
      // this.scene.fog = new Three.Fog(0xd5c0a4, 100, 100);
      this.group = new Three.Group();
      this.group.position.y = -1;

      // Floor
      // var TextureLoader = new Three.TextureLoader();
      // var texture = TextureLoader.load( '/img/three/line-texture-medium.jpg', function(texture) {
      //   texture.wrapS = texture.wrapT = Three.RepeatWrapping;
      //   texture.offset.set( 65, 65 );
      //   texture.repeat.set( 1500, 1500 );
      // });
      // var FloorMaterial = new Three.MeshPhongMaterial( {
      //   color: 0xffffff,
      //   specular:0x111111,
      //   shininess: 10,
      //   map: texture
      // });

      var PlaneGeometry = new Three.PlaneGeometry( 1500, 1500, 1, 1 );
      var FloorMaterial = new Three.MeshPhongMaterial( { color: 0x8b7a63, shininess: 0, reflectivity:0 } );
      var floor = new Three.Mesh( PlaneGeometry, FloorMaterial );
      floor.material.side = Three.DoubleSide;
      floor.rotation.x = Math.PI * -.5;
      floor.position.y = -2;
      // floor.castShadow = true;
      floor.receiveShadow = true
      this.scene.add( floor );

      // Load all models
      var modelNames = ["bubblesNew", "pipesNew", "circlesNew", "blobsNew", "duoNew"];
      var models = [];
      var loader = new GLTFLoader()


      for (var i = 0; i < modelNames.length; i++) {
        loadModel(modelNames[i]);
      }

      function loadModel(modelName) {
        loader.load('/img/three/' + modelName + '.glb', 
          function (gltf) {
            var obj = gltf.scene;
            obj.traverse( function( child ) {
              if ( child instanceof Three.Mesh ) {
                child.castShadow = true; 
                child.receiveShadow = false;
                if(child.material.map) child.material.map.anisotropy = 16; 
                // console.log(child.material);
              }
            })
            obj.modelName = modelName;
            models.push(obj);
            if (models.length === modelNames.length) {
              addBallsGroup();
              addPipesGroup();
              addCirclesGroup();
              addBlobsGroup();
              addFifthGroup();
            }
          }
          // function ( xhr ) {
          //   console.log( ( xhr.loaded / xhr.total * 100 ));
          // }
        );
      }


      // Balls Group
      function addBallsGroup () {
        var ballsGroup = new Three.Group();
        ballsGroup.name = "ballsGroup";
        var ballsClone = models.find(x => x.modelName == "bubblesNew");
        ballsClone.position.set(0, 0, 0);
        ballsClone.scale.x = ballsClone.scale.y = ballsClone.scale.z = 1.8;
        ballsGroup.add(ballsClone);
        ballsGroup.position.x = 0;
        ballsGroup.position.z = -4.95;
        ballsGroup.rotation.y = 3;
        self.group.add(ballsGroup);
      }

      // Pipes Group
      function addPipesGroup () {
        var pipesGroup = new Three.Group();
        pipesGroup.name = "pipesGroup";
        var pipesClone = models.find(x => x.modelName == "pipesNew");
        pipesClone.position.set(0, 0, 0);
        pipesClone.scale.x = pipesClone.scale.y = pipesClone.scale.z = 1;
        pipesGroup.add(pipesClone);
        pipesGroup.rotation.y = 1;
        pipesGroup.position.x = 4.71;
        pipesGroup.position.z = -1.45;
        self.group.add(pipesGroup);
      }

      // Circles Group
      function addCirclesGroup () {
        var circlesGroup = new Three.Group();
        circlesGroup.name = "circlesGroup";
        var circlesClone = models.find(x => x.modelName == "circlesNew");
        circlesClone.position.set(0, 0, 0);
        circlesClone.scale.x = circlesClone.scale.y = circlesClone.scale.z = 1.1;
        circlesGroup.add(circlesClone);
        circlesGroup.position.x = 1.91
        circlesGroup.rotation.y = 5;
        circlesGroup.position.z = 3.88;
        self.group.add(circlesGroup);
      }

      // Blobs Group
      function addBlobsGroup () {
        var blobsGroup = new Three.Group();
        blobsGroup.name = "blobsGroup";
        var blobsClone = models.find(x => x.modelName == "blobsNew");
        blobsClone.position.set(0, 0, 0);
        blobsClone.scale.x = blobsClone.scale.y = blobsClone.scale.z = 0.94;
        blobsGroup.add(blobsClone);
        blobsGroup.position.x = -3.65;
        blobsGroup.position.y = 1;
        blobsGroup.position.z = 4.08;
        self.group.add(blobsGroup);
      }

      // 5th element - temp Blobs Group
      function addFifthGroup () {
        var fifthGroup = new Three.Group();
        fifthGroup.name = "fifthGroup";
        var fifthClone = models.find(x => x.modelName == "duoNew");
        fifthClone.position.set(0, 0, 0);
        fifthClone.scale.x = fifthClone.scale.y = fifthClone.scale.z = 0.5;
        fifthGroup.add(fifthClone);
        fifthGroup.position.x = -4.71;
        fifthGroup.position.y = 1;
        fifthGroup.position.z = -1.455;
        self.group.add(fifthGroup);
      }


			// Lights
			// this.scene.add(new Three.AmbientLight(0xffffff));
      var light1 = new Three.SpotLight( 0xffffff, 1 );
      light1.position.set( -20, 100, 0 );
      light1.castShadow = true;
      light1.receiveShadow = false;
      light1.shadow.bias = -0.0001;
      light1.shadow.mapSize.width = 1024*2;
      light1.shadow.mapSize.height = 1024*2;
      this.scene.add(light1);

      var light2 = new Three.SpotLight( 0xffffff, 1 );
      light2.position.set( 10, 90, 0 );
      light2.castShadow = true;
      light2.receiveShadow = false;
      light2.shadow.bias = -0.0001;
      light2.shadow.mapSize.width = 1024*2;
      light2.shadow.mapSize.height = 1024*2;
      this.scene.add(light2);

      var light3 = new Three.SpotLight( 0xfff7cd, 0.5 );
      light3.position.set( -80, 0, -10 );
      light3.castShadow = true;
      light3.receiveShadow = false;
      light3.shadow.bias = -0.0001;
      light3.shadow.mapSize.width = 1024*2;
      light3.shadow.mapSize.height = 1024*2;
      this.scene.add(light3);

      var light4 = new Three.SpotLight( 0xfff7cd, 1 );
      light4.position.set( 20, 0, 50 );
      light4.castShadow = true;
      light4.receiveShadow = false;
      light4.shadow.bias = -0.0001;
      light4.shadow.mapSize.width = 1024*2;
      light4.shadow.mapSize.height = 1024*2;
      this.scene.add(light4);


      // Hemi light
      // var hemiLight = new Three.HemisphereLight(0xffffff, 0xffffff, 0.2);
      // this.scene.add(hemiLight);

      // Ambient
      // var lightAmbient = new Three.AmbientLight( 0x404040 ); // soft white light
      // this.scene.add( lightAmbient );

      // Light Helpers
      // var light1Helper = new Three.SpotLightHelper( light1, 10 );
      // this.scene.add( light1Helper );

      // Add groups
      this.scene.add(this.group);
      

      // Helpers
      // var axesHelper = new Three.AxesHelper( 5 );
      // this.scene.add( axesHelper );

      // Rendered settings
      this.renderer = new Three.WebGLRenderer({antialias: true});
      this.renderer.setSize(window.innerWidth, window.innerHeight);
      this.renderer.setClearColor( 0xf9eeda, 1 );
      this.renderer.gammaOutput = true;
      this.renderer.powerPreference = "high-performance";

      if (window.innerWidth > 1100) {
        this.renderer.shadowMap.enabled = true;
        this.renderer.shadowMap.type = Three.PCFSoftShadowMap;
      }

			//controls
			this.controls = new OrbitControls(this.camera, this.renderer.domElement);
			this.controls.zoomSpeed = 0.2
			this.controls.panSpeed = 0.2;
			this.controls.enableZoom = true;
			this.controls.target.set(0, 0, 0 );
			this.controls.update();
      
      container.appendChild(this.renderer.domElement);
			window.addEventListener( 'resize', this.onWindowResize, false );
    },

    onWindowResize: function () {
			this.camera.aspect = window.innerWidth / window.innerHeight;
			this.camera.updateProjectionMatrix();
			this.renderer.setSize(window.innerWidth, window.innerHeight);
    },

    animate: function() {
      requestAnimationFrame(this.animate);
      // this.stats.update()
      this.camera.position.x = this.cameraPos.x;
      this.camera.position.y = this.cameraPos.y;
      this.camera.position.z = this.cameraPos.z;

      if (this.group.children.length === 5) {
        this.group.children[0].rotation.y += 0.005;
        this.group.children[1].rotation.y -= 0.005;
        this.group.children[3].rotation.z -= 0.002;
        this.group.children[4].rotation.y -= 0.005;
        this.group.children[4].rotation.z -= 0.008;
      }

      // this.group.children[3].children[0].rotation.z += 0.01;

      // this.group.children[4].rotation.y += 0.01;
      // this.group.children[4].rotation.z += 0.007;

      if (this.sceneFreeSpin) {
        this.sceneRotation.y -= 0.005;
      }
      this.group.rotation.y = this.sceneRotation.y;

      this.controls.update();
      this.renderer.render(this.scene, this.camera);
      this.camera.lookAt(this.scene.position);
    },

    drunkCamera: function () {
      var self = this;
      var currentCamera = self.cameraPos;
      gsap.to(self.cameraPos, {x: (currentCamera.x + 0.1), duration: 5, repeat: -1, yoyo: true, ease: "power2.inOut"})
      gsap.to(self.cameraPos, {y: (currentCamera.y + 0.1), duration: 8, repeat: -1, yoyo: true, ease: "power2.inOut"})
      // gsap.to(self.cameraPos, {z: (currentCamera.z + 0.02), duration: 3, repeat: -1, yoyo: true, ease: "power2.inOut"})
    }
  },
  mounted() {
    var self = this;
    this.init();
    this.animate();
    if (this.$store.state.homeIntroPresented) {
      
      this.sceneFreeSpin = false
      this.cameraPos = {x: 0, y:1, z: 8}

      var index = self.$store.state.hubSlideNumber;

      console.log('scene homeIntroPresented', index, this.$store.state.cameraMoveTo);
      if (index == 0) {
        this.$store.state.cameraMoveTo = 'exp0';
        // this.cameraPos = {x: 0, y:1, z: 12}
      } else if (index == 1) {
        this.$store.state.cameraMoveTo = 'exp1';
        // this.cameraPos = {x: 0, y:1, z: 12}
      } else if (index == 2) {
        this.$store.state.cameraMoveTo = 'exp2';
        // this.cameraPos = {x: 0, y:1, z: 12}
      } else if (index == 3) {
        this.$store.state.cameraMoveTo = 'exp3';
      } else if (index == 4) {
        this.$store.state.cameraMoveTo = 'exp4';
      }
      console.log(this.cameraMoveToComp);

      this.$forceUpdate(); 

    } else {
      console.log('NO scene homeIntroPresented');
      this.$store.state.cameraMoveTo = "init";
      gsap.to(self.cameraPosRepeat, {x: 0.05, duration: 6, repeat:-1, yoyo:true, ease: "expo.inOut"});
      gsap.to(self.cameraPosRepeat, {y: 0.2, duration: 4, repeat:-1, yoyo:true, ease: "expo.inOut"});
    }

  },
  watch: {
    // activeSlider:function () {
    //   var self = this
    //   // gsap.to(self.animatedCamera, {y:self.activeSlider, duration: 2, ease: "power1.outout"})
    //   var activeSliderToAnim = 0;
    //   // if (activeSliderToAnim === -1) {
    //   //   activeSliderToAnim = 
    //   // }
    // },

    cameraMoveToComp: function (to, from) {
      var self = this;
      console.log(to, from)
      if (to === 'init') {
        gsap.to(self.cameraPos, {x: 0, y:5, z: 3, duration: 2})
      }
      if (to === 'browseInit') {
        console.log('browseInit')
        this.sceneFreeSpin = false
        gsap.to(self.cameraPos, {x: 0, y:0.5, z: 9, duration: 2, onComplete:function(){
          self.drunkCamera()
        }})
        gsap.to(self.sceneRotation, {x: 0, y: 4.55, z: 0, duration: 2})
      }
      if (to === 'zoomOut') {
        console.log('zoomOut');
        gsap.to(self.cameraPos, {z: 16, duration: 3})
      }
      if (to === 'exp0' || to === 'exp1' || to === 'exp2' || to === 'exp3' || to === 'exp4') {
        gsap.to(self.cameraPos, {z: 9, duration: 3, ease: "expo.inOut", onComplete:function(){
          self.drunkCamera()
        }})
      }

      if (to === 'exp0') {
        console.log('exp0');
        gsap.to(self.sceneRotation, {x: 0, y:4.55, z: 0, duration: 1, ease: "expo.inOut"})
      }
      if (to === 'exp1') { // Circles
        console.log('exp1');
        gsap.to(self.sceneRotation, {x: 0, y:5.9, z: 0, duration: 1, ease: "expo.inOut"});
      }
      if (to === 'exp2') {
        console.log('exp2');
        gsap.to(self.sceneRotation, {x: 0, y:7.1, z: 0, duration: 1, ease: "expo.inOut"})
      }
      if (to === 'exp3') {
        console.log('exp3');
        gsap.to(self.sceneRotation, {x: 0, y: 1.95, z: 0, duration: 1, ease: "expo.inOut"})
      }
      if (to === 'exp4') {
        console.log('exp4');
        gsap.to(self.sceneRotation, {x: 0, y: 3.2, z: 0, duration: 1, ease: "expo.inOut"})
      }
      if (to === 'external') {
        gsap.to(self.cameraPos, {x: 0, y:28, z: 0, duration: 2, ease: "expo.inOut"})
      }
    }

  }
}
</script>
